// all the js for the map functionality
import $ from "jquery";

function initMap(addresses) {
    var mapElement = document.getElementById("map");

    // Use a ternary operator for map options
    var mapOptions = {
        zoom: addresses.length === 1 ? 15 : 5,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapId: "a7aa6b3f9d066e80",
    };

    var map = new google.maps.Map(mapElement, mapOptions);
    var markers = {};
    var infoWindows = {};
    var openInfoWindow = null; // Track the currently open InfoWindow

    addresses.forEach(function (address) {
        if (address.lat !== null && address.lng !== null) {
            var marker = new google.maps.Marker({
                position: { lat: address.lat, lng: address.lng },
                map: map,
                icon: {
                    path: "M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z",
                    fillColor: "#87CEEB",
                    fillOpacity: 1,
                    strokeColor: "#000000",
                    strokeWeight: 1,
                    scale: 0.06,
                    anchor: new google.maps.Point(192, 512),
                    labelOrigin: new google.maps.Point(192, 192),
                },
            });

            // Create content for the InfoWindow
            var contentString = `
                  <div style="height: 100px;">
                      <p class="text-xl"><strong>${
                          address.claimNumber
                      }</strong></p>
                      <p class="mb-4">${address.value}</p>
                      <a class="px-3 py-2 rounded-md text-white bg-sp-primary hover:text-gray-700" tabindex="-1" href="https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                          address.value
                      )}" target="_blank">
                          Open in Google Maps
                      </a>
                  </div>`;

            var infoWindow = new google.maps.InfoWindow({
                content: contentString,
                maxWidth: 250,
            });

            markers[address.claimNumber] = marker;
            infoWindows[address.claimNumber] = infoWindow;

            if (addresses.length === 1) {
                infoWindow.open(map, marker);
                google.maps.event.addListener(
                    infoWindow,
                    "domready",
                    function () {
                        document.activeElement.blur();
                    }
                );
            }

            google.maps.event.addListener(marker, "click", function () {
                infoWindow.open(map, marker);
            });

            map.setCenter({ lat: address.lat, lng: address.lng });
        } else {
            console.log("Geocoding was not successful for address:", address);
        }
    });

    if (addresses.length !== 1) {
        document.querySelectorAll("a[data-index]").forEach(function (button) {
            button.addEventListener("click", function (e) {
                e.preventDefault();
                document.getElementById("mapBlock").classList.toggle("hidden");
                document.getElementById("dataIndex").classList.toggle("hidden");
                document.getElementById("mapIcon").classList.toggle("hidden");
                document
                    .getElementById("inspectionsIcon")
                    .classList.toggle("hidden");
                document.getElementById("mapIcon1").classList.toggle("hidden");
                document
                    .getElementById("inspectionsIcon1")
                    .classList.toggle("hidden");
                document
                    .getElementById("map")
                    .scrollIntoView({ behavior: "smooth" });

                var index = this.getAttribute("data-index");
                var marker = markers[index];
                var infoWindow = infoWindows[index];

                if (marker && infoWindow) {
                    if (openInfoWindow) {
                        openInfoWindow.close();
                    }

                    infoWindow.open(map, marker);
                    openInfoWindow = infoWindow;
                    map.setCenter(marker.getPosition());
                }
            });
        });
    }
}

// Check if we are on the inspections page
if (
    window.location.pathname === "/inspections" ||
    window.location.pathname.match(/^\/inspections\/\d+$/)
) {
    window.onload = function () {
        var address = document.getElementById("addresses");
        var addresses = Array.from(address.getElementsByTagName("li")).map(
            (li) => {
                return {
                    value: li.textContent,
                    claimNumber: li.getAttribute("data-claim-number"),
                    lat: parseFloat(li.getAttribute("data-lat")),
                    lng: parseFloat(li.getAttribute("data-lng")),
                };
            }
        );
        initMap(addresses);
    };

    // Toggle the map visibility
    $(".toggleMapBtn").click(function (event) {
        event.preventDefault();
        $("#mapBlock").toggleClass("hidden");
        $("#dataIndex").toggleClass("hidden");
    });
}
